import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./basic/en.json";
import translationZh from "./basic/zh.json"
import homeEn from './home/en.json'
import homeZh from './home/zh.json'
import {docEn, docZh, shortcutDocEn, shortcutDocZh} from "./docs/docs";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    // lng: "en",
    fallbackLng: "en",

    // keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: {
            ...translationEn,
            ...homeEn,
            "docs": {
                "shortcut": shortcutDocEn,
                "doc": docEn,
            }
        },
      },
      zh: {
          translations: {
              ...translationZh,
              ...homeZh,
              "docs": {
                  "shortcut": shortcutDocZh,
                  "doc": docZh
              }
          },
      }
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;