export const shortcutDocZh = `
> win和linux中, 请把以下快捷键的cmd换成control

| 功能                   | 快捷键             | 提示    |
| ---------------------- | ------------------ | ------- |
| 全局召出窗口           | shift + cmd + t    | tracker |
| 新建事件/待办事件      | cmd + enter        |         |
| 设置事件的持续时间     | cmd + enter        |         |
| 打开事件对应的超链接   | cmd + 点击事件     |         |
| 新建项目               | cmd + p            | project |
| 新建或者修改任务ID     | cmd + i            | id      |
| 生成随机的任务ID       | shift + cmd + i    |         |
| 修改项目名或者项目地址 | cmd + 点击项目标签 |         |
`
export const shortcutDocEn = `
> In win or linux, please replace the cmd in following shortcuts to control
> Task ID doesn't exist on todo events 

| Action                                           | Shortcut                    | Hint    |
| ------------------------------------------------ | --------------------------- | ------- |
| Globally show Time Tracker                       | shift + cmd + t             | tracker |
| Create or change a event or a todo event         | cmd + enter                 |         |
| Open the url of the event                        | cmd + click the event       |         |
| Create or change a project tag                   | cmd + p                     | project |
| Apply the last used project tag to current event | cmd + r                     | recent  |
| Change the detail(name, url) of a project        | cmd + click the project tag |         |
| Create or change the task ID tag                 | cmd + i                     | id      |
| Generate a random task ID                        | shift + cmd + i             |         |

`

export const docZh = '## 概述\n\nTime Tracker 主要记录两种信息: 事件和待办事件. 事件是你在做的事情, 待办事件是你计划要做的事情. \n你可以给事件和待办事件指定项目, 来将它们归类.\n你也可以给事件指定任务ID, 用来记录这个事件细节. \n\n## 切换语言\n\n在`窗口-语言`中切换语言.\n\n## 创建事件或者待办事件\n\n在第一行中输入对事件的描述, 可以用`cmd+p`为事件添加项目, `cmd+r`会自动添加最近使用的项目, `cmd+i`为事件添加任务ID.\n待办事件没有任务ID\n`cmd + enter`创建事件或者待办事件, 并且自动设置开始时间.\n\n## 结束事件\n\n按`结束`结束事件, 并且自动设置结束时间.\n如果你为事件规划了30分钟, 在`时长`栏中输入30, `cmd+enter`确认. 在事件到期时, 会有系统提示. \n你也可以提前结束事件.\n\n## 将事件变为待办事件\n\n点击`待办`将事件放回待办事件列表.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwignvw6mgj31go0letav.jpg)\n## 开始一个待办事件\n\n点击待办事件前的`开始`开始一个事件.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwignban7cj31go0le0v0.jpg)\n\n## 激活待办事件\n\n默认新建的待办事件处于激活状态, `cmd+点击待办事件`将事件变为灰色, 再次`cmd+点击`重新激活待办事件.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwigp16b5xj31go0py0w7.jpg)\n\n## 为项目设置超链接\n\n双击项目打开编辑面板, 假如在超链接中输入`http:\/\/www.baidu.com\/{query}`, `{query}`会被任务标签替换.\n`cmd+点击事件`会在浏览器中打开`http:\/\/www.baidu.com\/taskID`. \n\n如果用任务记录GitHub的commit, 超链接可以直接输入GitHub的仓库地址.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwig73tigkj31050u00ut.jpg)'

export const docEn = '## 概述\n\nTime Tracker 主要记录两种信息: 事件和待办事件. 事件是你在做的事情, 待办事件是你计划要做的事情. \n你可以给事件和待办事件指定项目, 来将它们归类.\n你也可以给事件指定任务ID, 用来记录这个事件细节. \n\n## 切换语言\n\n在`窗口-语言`中切换语言.\n\n## 创建事件或者待办事件\n\n在第一行中输入对事件的描述, 可以用`cmd+p`为事件添加项目, `cmd+r`会自动添加最近使用的项目, `cmd+i`为事件添加任务ID.\n待办事件没有任务ID\n`cmd + enter`创建事件或者待办事件, 并且自动设置开始时间.\n\n## 结束事件\n\n按`结束`结束事件, 并且自动设置结束时间.\n如果你为事件规划了30分钟, 在`时长`栏中输入30, `cmd+enter`确认. 在事件到期时, 会有系统提示. \n你也可以提前结束事件.\n\n## 将事件变为待办事件\n\n点击`待办`将事件放回待办事件列表.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwignvw6mgj31go0letav.jpg)\n## 开始一个待办事件\n\n点击待办事件前的`开始`开始一个事件.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwignban7cj31go0le0v0.jpg)\n\n## 激活待办事件\n\n默认新建的待办事件处于激活状态, `cmd+点击待办事件`将事件变为灰色, 再次`cmd+点击`重新激活待办事件.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwigp16b5xj31go0py0w7.jpg)\n\n## 为项目设置超链接\n\n双击项目打开编辑面板, 假如在超链接中输入`http:\/\/www.baidu.com\/{query}`, `{query}`会被任务标签替换.\n`cmd+点击事件`会在浏览器中打开`http:\/\/www.baidu.com\/taskID`. \n\n如果用任务记录GitHub的commit, 超链接可以直接输入GitHub的仓库地址.\n\n![](https:\/\/tva1.sinaimg.cn\/large\/008i3skNgy1gwig73tigkj31050u00ut.jpg)'