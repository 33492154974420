const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/documentation"],
    exact: true,
    component: "Documentation"
  },
  {
    path: ["/shortcuts"],
    exact: true,
    component: "Shortcuts"
  }
];

export default routes;
